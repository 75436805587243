import React from 'react';
import { styled } from '@mui/material/styles';
import { useStaticQuery, graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleHeader from '@components/TitleHeader'
import { Typography, Container, Grid } from '@mui/material';
import clsx from 'clsx'
import EnvelopeIcon from '@assets/icons/envelope_secondary.svg';
import PhoneIcon from '@assets/icons/phone_secondary.svg';
import AddressIcon from '@assets/icons/address_primary.svg';
import PersonIcon from '@assets/icons/person_primary.svg';

const PREFIX = 'ContactPage';

const classes = {
  container: `${PREFIX}-container`,
  ul: `${PREFIX}-ul`,
  li: `${PREFIX}-li`,
  icon: `${PREFIX}-icon`,
  link: `${PREFIX}-link`,
  frameContainer: `${PREFIX}-frameContainer`,
  iframe: `${PREFIX}-iframe`,
  textContainer: `${PREFIX}-textContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    padding: theme.spacing(2)
  },

  [`& .${classes.ul}`]: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },

  [`& .${classes.li}`]: {
    color: theme.palette.primary.main,
    padding: 0,
    margin: theme.spacing(2),
    marginLeft: 0,
    marginBottom: theme.spacing(4),
    fontFamily: 'Prompt !important',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 16,
    [theme.breakpoints.up("md")]: {
      fontSize: 20
    },
    '& span': {
      lineHeight: 1.6,
      verticalAlign: 'bottom',
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },

  [`& .${classes.icon}`]: {
    fill: theme.palette.primary.main,
    marginRight: theme.spacing(1 / 2),
    width: 14,
    height: 14,
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    textDecorationSkipInk: 'none',
    '&:hover': {
      textDecoration: 'none'
    },
    '&:hover *': {
      color: theme.palette.secondary.main,
      fill: theme.palette.secondary.main,
    }
  },

  [`& .${classes.frameContainer}`]: {
    marginTop: theme.spacing(2),
    position: 'relative',
    paddingBottom: '56.25%', /* 16:9 */
    height: 0,
    marginBottom: theme.spacing(2)
  },

  [`& .${classes.iframe}`]: {
    position: 'absolute',
    border: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },

  [`& .${classes.textContainer}`]: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(8)
    }
  }
}));

function ContactPage() {

  const { allWpContactInfoItem: { settingList: [ firstSettingGroup ] } } = useStaticQuery(graphql`
    query Contact {
      allWpContactInfoItem(limit: 1) {
        settingList: nodes {
          contact_info {
            addressLink
            addressText
            facebookLink
            email
            fieldGroupName
            phone
            phoneText
            workingTime
          }
        }
      }
    }
  `)
  const { contact_info: contactInfo } = firstSettingGroup
  
  return (
    (<Root>
      <SEO 
        title="Contact Us - Grain Augers Manufacturer Farm Equipment Plus LLC" 
        description="Looking for high-quality grain conveyors, farm conveyors, grain legs, grain elevators, and grain augers? Contact us today for a free quote and expert advice. Our experienced team is here to help you with all your grain handling needs." />
      <Container maxWidth="md" disableGutters>
        <div className={classes.container}>
          <TitleHeader
            title='Contact'
          />
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <ul className={classes.ul}>
                <li className={clsx(classes.li, classes.person)}>
                  <PersonIcon className={classes.icon} />
                  <Typography component="span" color="textPrimary">{contactInfo?.person || 'Amos Wipf'}</Typography>
                </li>
                <li className={clsx(classes.li, classes.phone)}>
                  <a href={`callto:${contactInfo.phone}`} className={clsx(classes.link)} alt="phone">
                    <PhoneIcon className={classes.icon} />
                    <Typography component="span" color="textPrimary">{contactInfo.phoneText}</Typography>
                  </a>
                </li>
                <li className={clsx(classes.li, classes.envelope)}>
                  <a href={`mailto:${contactInfo.email}`} className={clsx(classes.link)} alt="email">
                    <EnvelopeIcon className={classes.icon} />
                    <Typography component="span" color="textPrimary">{contactInfo.email}</Typography>
                  </a>
                </li>
                <li className={clsx(classes.li, classes.address)}>
                  <AddressIcon className={classes.icon} />
                  <Typography component="span" color="textPrimary">{contactInfo.addressText}</Typography>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} md={8}>
              <div className={classes.frameContainer}>
                <iframe
                  title="Our address on Google"
                  className={classes.iframe}
                  src={contactInfo.addressLink}
                  width="100%"
                  height="auto"
                  allowFullScreen=""
                  loading="lazy" />
              </div>
              <Grid container className={classes.textContainer}>
                <Grid item xs={12} md={7}>
                  <Typography color="textPrimary" paragraph>
                    We have manufactured from 2010 and sold more than 2000 conveyors. Our product is of high quality and easy to use.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7} style={{ marginLeft: 'auto'}}>
                  <Typography color="textPrimary" paragraph>
                    We produce conveyors that help you deliver the product from anywhere without loss.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Root>)
  );
}

export default ContactPage;